const Cobalt = Cobalt || {};

Cobalt.notifications = {};

Cobalt.notifications.orangeAlert = (($)=>{
  const init = () => {
    // Get the time stamp to prevent the browser from caching the RSS feed
    const time = new Date().getTime();

    $.get(`//www.getrave.com/rss/syr/channel1?${time}`, (data) => {
      const items = $(data).find('item');
      if (items.length > 0) {
        const $this = $(items[0]);
        const alert = {
          title: $this.find('title').text(),
          link: $this.find('link').text(),
          description: $this.find('description').text(),
          pubDate: $this.find('pubDate').text(),
          author: $this.find('author').text(),
        };

        if (alert.description !== 'ALL CLEAR') {
          $('#orange-alert')
            .addClass('visible')
            .html(`
              <div class="container">
                <div class="cell icon">
                  <span class="ci ci-warning" aria-hidden="true"></span>
                </div>
                <div class="cell alert">
                  <p>${alert.description}</p>
                </div>
              </div>
            `);
        }
      }
    });
  };

  return init;
})(jQuery);