/* global window */
(function(window) {'use strict';

var document = window.document,
	parseInt = window.parseInt,
	Math = window.Math,
	mathFloor = Math.floor,
	mathRound = Math.round,
	Modernizr = window.Modernizr,
	$ = window.jQuery,
	_COBALT = window._COBALT,
	dppxList = _COBALT.dppx,
	devicePixelRatio = window.devicePixelRatio || 1,
	$window = $(window),
	$document = $(document),
	$html = $('html');

function init()
{
	initDPPX();

	// A bunch code collected over the years to help with accessibility and other random things
	$document
		.ready(function(){
			if(window.innerWidth < 769){
				$('.primary-nav .parent a').removeAttr('aria-expanded');
			}
		})
		//Prevent setting the URL to an empty hash via link.
		.on('click', 'a[href="#"]', function(e) {
			e.preventDefault();
		})
		//Remove the left-over inline-styles.
		.on('hidden.bs.collapse', '.primary-nav .parent > button + .children, .primary-nav .navbar-collapse', function() {
			$(this).css('height', '');
		})
		// on hover toggle aria-expanded
		.on('mouseover', '.primary-nav .parent', function(){
			var $this = $(this);
			if ($this.children('.children').is(':visible') && window.innerWidth >= 769){
				$this.children('a').attr('aria-expanded', true);
			}
		})
		.on('mouseout', '.primary-nav .parent', function(){
			var $this = $(this);
			if (!$this.children('.children').is(':visible') && window.innerWidth >= 769){
				$this.children('a').attr('aria-expanded', false);
			}
		})
		//Add collapse toggle to nav buttons.
		.on('click', '.primary-nav .parent > button', subnavToggle)
		//Prevent instant touch navigation when expanding nav menu.
		.on('touchstart', '.primary-nav .parent > a', function() {
			var $this = $(this),
				$parent = $this.parent();
			//If the expansion button is hidden, toggle the hover class, and if the hover class was added prevent the next click.
			if (!$parent.children('button').is(':visible') && $parent.toggleClass('hover').hasClass('hover')) {
				$this.one('click', function(e) {
					e.preventDefault();
				});
			}
		})
		//Remove the class added by the touch prevention.
		.on('touchstart', function(e) {
			if (!$(e.target).is('.primary-nav .parent *')) {
				$('.primary-nav .parent.hover').removeClass('hover');
			}
		})
		//Focus nav.
		.on('focusin', function(e) {
			var $target = $(e.target),
				base = '.primary-nav .navbar-nav',
				$hover = $(base + ' .hover');
			//Check if a nav link targeted.
			if ($target.is(base + ' a')) {
				//Find and open the hovered parents.
				var $parents = $target.parents(base + ' .parent').addClass('hover');

				// Close anything not in that list and then change aria-expanded to false.
				var $notParents = $hover.not($parents);
				$notParents.removeClass('hover');
			}
			else {
				//Close all.
				$hover.removeClass('hover');
			}
		})
		//Card slider click handler.
		.on('click', '.card-slider .card-btn', function() {
			var $this = $(this),
				dir = $this.attr('data-dir'),
				$card_slider = $this.closest('.card-slider'),
				index = parseInt($card_slider.attr('data-index') || 0, 10) || 0;
			updateSlideIndex(dir, $card_slider, index);
			cardSliderRefresh($card_slider);
		})
		.on('focus', '.card-slider .card-wrap-slide > article', function() {
			var $this = $(this),
				$card_slider = $this.closest('.card-slider');
			//There is an accessibility feature in browsers where overflow: hidden element will scroll to show focused content.
			//In this case, doing so murders the accessibility of the slider.
			//It is better to make the slider slide to show that content, than scroll and break.
			$this.closest('.card-wrap').scrollLeft(0);
			//Instead, slide to the index of the focused element if inactive.
			if ($this.hasClass('inactive')) {
				$card_slider.attr('data-index', $this.index());
				cardSliderRefresh($card_slider);
			}
		})

		.on( 'submit', '.search-syr', function( e ) {

			e.preventDefault();

			var input = encodeURIComponent( $.trim( $( '.search-wrap .search-syr input[name="q"]' ).val() ) ).replace( /%20/g, '+' );

			window.location.href = 'https://www.syracuse.edu/search/?q=' + input;

		})

		// Make boxes with images clickable Required for accessability

		.on('click', '.box-clickable', function (evt) {
			evt.stopPropagation();
			var t = $(this);
			t.find('a')[0].click();
		})

		// collapse fixes
		.on('hidden.bs.collapse', function(evt) {
			// Untrap keyboard in the case of the cta
			if(evt.target.id === 'call-to-action-pop-up'){
				var focus = $('#featureOpenLink');
				keyboardUnTrap($('#call-to-action-feature'), focus);
			}
		})
		.on('shown.bs.collapse', function(evt) {
			// Trap keyboard in the case of the cta
			if(evt.target.id === 'call-to-action-pop-up'){
				var focus = $('#featureOpenLink');
				focus.focus();
				keyboardTrap($('#call-to-action-feature'), focus);
			}
		});

		function keyboardUnTrap(parent, focus) {
			$('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').attr('tabindex', 0);
			// parent.find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').attr('tabindex', -1);
			parent.off('keyup', function(e) {
				if (e.keyCode === 9) {
					if (parent.find(document.activeElement).length < 1) {
						focus.focus();
					}
				}
			})
		  };

		function keyboardTrap(parent, focus) {
			$('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').attr('tabindex', -1);
			parent.find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').attr('tabindex', 0);
			parent.on('keyup', function(e) {
				if (e.keyCode === 9) {
					if (parent.find(document.activeElement).length < 1) {
						focus.focus();
					}
				}
			})
		};

		function moveAriaExpanded(){
			var $link = $('.primary-nav .parent a');
			var $button = $('.primary-nav .parent button');
			if(window.innerWidth < 769){
				$link.removeAttr('aria-expanded');
				$button.attr('aria-expanded', false);
			}else{
				$button.removeAttr('aria-expanded');
				$link.attr('aria-expanded', false);
			}
		}


	$window
		.on('resize', function() {
			updateTitleBar();
			cardSliderRefreshAll();
			moveAriaExpanded();
		});

}

function ready () {
	initSidebar();
	cardSliderRefreshAll();
	updateTitleBar();
	closeNavigation();
	cardSwipe();
	$('iframe[src*="youtube"]').parent().fitVids();
	$('iframe[src*="vimeo"]').parent().fitVids();
}

function cardSwipe() {
	var touchstartX = 0;
	var touchstartY = 0;
	var touchendX = 0;
	var touchendY = 0;

	var gesturedZones = document.getElementsByClassName('card-slider');

	for (var i = 0; i < gesturedZones.length; i++) {
		gesturedZones[i].addEventListener('touchstart', function(event) {
				touchstartX = event.changedTouches[0].clientX;
				touchstartY = event.changedTouches[0].clientY;
		}, false);

		gesturedZones[i].addEventListener('touchend', function(event) {
				touchendX = event.changedTouches[0].clientX;
				touchendY = event.changedTouches[0].clientY;
				handleGesture($(this));
		}, false);
	}


	function handleGesture($this) {
		var swiped = 'swiped: ';
		if (touchendX < touchstartX) {
			var dir = 'r',
			$card_slider = $this.closest('.card-slider'),
			index = parseInt($card_slider.attr('data-index') || 0, 10) || 0;
			updateSlideIndex(dir, $card_slider, index);
			cardSliderRefresh($card_slider);
		}
		if (touchendX > touchstartX) {
			var dir = 'l',
			$card_slider = $this.closest('.card-slider'),
			index = parseInt($card_slider.attr('data-index') || 0, 10) || 0;
			updateSlideIndex(dir, $card_slider, index);
			cardSliderRefresh($card_slider);
		}
	}
}

function updateSlideIndex(dir, $card_slider, index) {
	switch ( dir ) {
		case 'r':
			$card_slider.attr('data-index', index + 1);
			break;
		case 'l':
			$card_slider.attr('data-index', index - 1);
			break;
	}
}

function initDPPX() {
	//Find the highest matching dppx, rounding down.
	var i,
		dppx = 1;
	if (dppxList && (i = dppxList.length)) {
		while (i--) {
			if (devicePixelRatio >= dppxList[i]) {
				dppx = dppxList[i];
				break;
			}
		}
	}
	//Update the default attribute.
	$html.attr('data-dppx', dppx);
}

function initSidebar() {

	$('.sidebar .page-menu ul li a').each(function() {
		var child = false;
		var indent = '';
		var $parent = $(this).parent('li').parent('ul').parent('li');
		//Check to see if this link is a child and also a child of the current page
		if ($parent.hasClass('page_item_has_children')) {
			child = true; //This is a child link
		}
	});
}

function updateTitleBar() {

	var $branding  = $('.navbar div.row-header .container .branding:first');
	var $logotype  = $('.navbar div.row-header .container .branding:first .branding-upper');
	var $separator = $('.navbar div.row-header .container .branding:first .branding-sep');
	var $title     = $('.navbar div.row-header .container .branding:first .branding-lower');

	var windowWidth = $(window).width();
	var sumWidth = Math.floor($logotype.outerWidth(true)) + Math.floor($separator.outerWidth(true)) + Math.floor($title.outerWidth(true));

	if ( sumWidth > $branding.innerWidth() ) {
		//console.log('    verdict: TOO BIG!!!');
		$branding.find('.branding-sep').css({'border-color':'transparent'});
		//$title.css({'margin-top':'0.25em'});
	} else {
		//console.log('    verdict: OK...');
		$branding.find('.branding-sep').css({'border-color':'#adb3b8'});
		$title.css({'margin-top':'0'});
	}

}

function cardSliderRefresh($card_slider) {

	var $card_wrap = $card_slider.find('.card-wrap'),
		$card_wrap_slide = $card_wrap.find('.card-wrap-slide'),
		$card_slides = $card_wrap_slide.children(),
		cardWrapWidth = $card_wrap.width(),
		cardWidth = $card_slides.width(),
		cardWidthPercentage = ((cardWidth && cardWrapWidth) ? mathFloor(cardWidth / cardWrapWidth * 100) / 100 : 1) || 1,
		cardCound = $card_slides.length,
		visibleCards = mathRound(1 / cardWidthPercentage) || 1,
		indexMax = cardCound > visibleCards ? cardCound - visibleCards : 0,
		index = parseInt($card_slider.attr('data-index') || 0, 10) || 0,
		indexVisibleEnd,
		percent,
		delta;
	//Normalize index.
	index = index < 0 ? 0 : (index > indexMax ? indexMax : index);
	indexVisibleEnd = index + visibleCards;
	$card_slider.attr('data-index', index);
	//Set the left value, using transforms if possible.
	percent = '-' + (index / visibleCards * 100) + '%';
	delta = '-' + (index * cardWidth) + 'px';
	$card_wrap_slide.css('transform', 'translateX(' + delta + ')');
	$card_slides.each(function(i) {
			var active = (i >= index && i < indexVisibleEnd);
			$(this)
				.removeClass(active ? 'inactive' : 'active')
				.addClass(active ? 'active' : 'inactive');
		});
	$card_slider
		.attr('data-canslide', cardCound > visibleCards ? 1 : 0)
		.attr('data-end', index === 0 ? 'l' : (index === indexMax ? 'r' : '' ));
}

function cardSliderRefreshAll() {
	$('.card-slider').each(function() {
		cardSliderRefresh($(this));
	});
}

function closeNavigation(){
	// if the navbar collapse div is open and (you click outside of it or the escape key is pressed) then trigger a click on the toggle button
	$(document).on('click', function(e) {
		setTimeout(function(){
			var isOpen = $('.navbar-collapse').hasClass('in');
			var focus = $(e.target);
			if (isOpen === true && focus.closest('#header-nav-container').length == 0) {
				$('button.navbar-toggle').click();
			}
		}, 100);
	})
	.on('keydown', function(e) {
		var isOpen = $('.navbar-collapse').hasClass('navbar-collapse collapse in');
		if (isOpen === true && e.keyCode === 27) {
			$('button.navbar-toggle').click();
		}
	})

	// Close mobile nav if focus leaves ie: tab past the search button which is the last focusable element.
	$('.js-header-search').on('keydown', function(e){
		var isOpen = $('.navbar-collapse').hasClass('navbar-collapse collapse in');
		if (isOpen === true && e.keyCode === 9 && !e.shiftKey) {
			$('button.navbar-toggle').click();
		}
	})
}

function subnavToggle(e) {
	e.preventDefault();
	var $this = $(this);
	var $children = $this.siblings('.children');
	// collapse all
	$('.children').collapse('hide');
	$children.collapse('show');

	$('.children').on('hidden.bs.collapse', function(evt){
		$(evt.target).siblings('button').attr("aria-expanded", false);
		$(evt.target).removeAttr('aria-expanded');
	});
	$('.children').on('shown.bs.collapse', function(evt){
		$(evt.target).siblings('button').attr("aria-expanded", true);
		$(evt.target).removeAttr('aria-expanded');
	});
}

init();

$(function() {
	ready();
	Cobalt.notifications.orangeAlert();
});

})(window);
