/**
 * Fetch SU Calendar Events
 */
function fetchSuEvents(appendUrl, callback) {
  const eventsUrl = 'https://calendar.syracuse.edu/wp-json/su-events/v1/events' + appendUrl;
  let fetchData = {
    method: 'get',
    headers: new Headers({
      'Authorization': 'su-events-key ' + _COBALT.eventParams.auth,
    })
  }
  fetch(eventsUrl, fetchData)
  .then(resp => {
    if (resp.ok) {
      return resp.json();
    }
  })
  .then((events) =>
      callback(null, events)
  )
  .catch(function(error) {
    callback(error, null)
  });
}

/**
 * Get upcoming events
 */
function getUpcomingEvents(callback){
  //generate the request url
  const params = _COBALT.eventParams;
  const append = [];
  if (params.org) {
    append.push('organizer=' + params.org);
  }
  if (params.group) {
    append.push('group=' + params.group);
  }
  if (params.categories) {
    append.push('category=' + params.categories)
  };
  if (params.excludeCats) {
      append.push('exclude_category=' + params.excludeCats)
    };
  if (params.excludeTypes) {
      append.push('exclude_event_type=' + params.excludeTypes)
    };
  let appendUrl = '';
  if (append[0]) {
    appendUrl += '?' + append[0];
    for (let i=1; i<append.length; i++){
      appendUrl += '&' + append[i];
    }
    appendUrl += '&per_page=20';
  }
  // get the specific events to exclude
  const remove = params.removeEvents;
  //fetch the events if group/organizer etc chosen
  if (appendUrl !== ''){
    fetchSuEvents(appendUrl, (error, results) => {
      if (error) {
        callback('error fetching event data', null);
      } else {
        const events = results.data;
        //remove the specific events to exclude
        const filtered = events.filter(event => !remove.includes(event.slug));
        callback(null, filtered);
      }
    });
  } else {
    callback('No event group or type was chosen', null);
  }
}

/**
 * Get featured events
 */
function getFeaturedEvents(callback){
  const featEvents = _COBALT.eventParams.featEvents;
  const appendUrl = '?include_postnames=' + featEvents;
  if (appendUrl !== ''){
    fetchSuEvents(appendUrl, (error, results) => {
      if (error) {
        callback('error fetching event data', null);
      } else {
        const events = results.data;
        events.sort(function(a, b){
          return new Date(a.startDateTime) - new Date(b.startDateTime)
        });
        callback(null, events);
      }
    });
  } else {
    callback('No upcoming featured events were chosen', null);
  }
}